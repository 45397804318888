import React from "react";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { makeStyles } from "@mui/styles";
import { Button } from "@ascension/ui-library";
import logo from "../../assets/ascension-rx-logo.svg";
import routes from "../../constants/routes";

const useStyles = makeStyles({
  header: {
    padding: "22px 32px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0,0,0,0.12)",
  },
  logo: { height: "40px" },
  link: { lineHeight: "0" },
});

const Header = () => {
  const classes = useStyles();

  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const logout = () => {
    const logoutRequest = {
      account: account,
      postLogoutRedirectUri: routes.HOME,
    };

    instance.logoutRedirect(logoutRequest);
  };

  return (
    <header className={classes.header}>
      <Link to={routes.HOME} className={classes.link}>
        <img src={logo} alt="Ascension Rx Logo" className={classes.logo} />
      </Link>
      <Button onClick={logout}>Log Out</Button>
    </header>
  );
};

export default Header;
