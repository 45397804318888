/* eslint-disable no-console */
export const authConfig = {
  nonProd: {
    applicationId: "2ca6c13c-1ec3-4d97-8b96-49164ae93422",
    authority:
      "https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com",
    scopes: [
      "https://ascensionidqa.onmicrosoft.com/rx-onboarding-api/app.access",
    ],
  },
  prod: {
    applicationId: "0cb384f4-df87-4b1f-bb85-092f3caca0dd",
    authority: "https://id.ascension.org/id.ascension.org",
    scopes: ["https://id.ascension.org/rx-onboarding-api/app.access"],
  },
};

export const baseConfig = {
  dev: {
    url: "https://dev-api.ascension.org/rxonboardingapi/",
    medsDataApi: "verifier/myMeds",
    apikey: "qNeaEfQZhAmasWzf4bLTdUOaGnRrt4ci",
  },
  qa: {
    url: "https://test-api.ascension.org/rxonboardingapi/",
    medsDataApi: "verifier/myMeds",
    apikey: "qNeaEfQZhAmasWzf4bLTdUOaGnRrt4ci",
  },
  uat: {
    url: "https://uat-api.ascension.org/rxonboardingapi/",
    medsDataApi: "verifier/myMeds",
    apikey: "qNeaEfQZhAmasWzf4bLTdUOaGnRrt4ci",
  },
  prod: {
    url: "https://prod-api.ascension.org/rxonboardingapi/",
    medsDataApi: "verifier/myMeds",
    apikey: "HWJ9RKLoSeszkQbqSdAhXoCrIAnGXDzJ",
  },
};

export const getEnvVars = (host = window.location.hostname) => {
  switch (true) {
    case host.includes("localhost"):
      return {
        apiConfig: baseConfig.dev,
        authConfig: authConfig.nonProd,
      };
    case host.includes("-dev"):
      return {
        apiConfig: baseConfig.dev,
        authConfig: authConfig.nonProd,
      };
    case host.includes("-qa"):
      return {
        apiConfig: baseConfig.qa,
        authConfig: authConfig.nonProd,
      };
    case host.includes("-uat"):
      return {
        apiConfig: baseConfig.uat,
        authConfig: authConfig.nonProd,
      };
    case host.includes("my-meds-ui.pub.cloud-03.pcf.ascension.org"):
      return {
        apiConfig: baseConfig.prod,
        authConfig: authConfig.prod,
      };
    case host.includes("rx.ascension.org"):
      return {
        apiConfig: baseConfig.prod,
        authConfig: authConfig.prod,
      };
    default:
      console.error("Failed to load configuration", {
        code: "env_not_detected",
        desc: "host url does not match known environments",
      });
      return {};
  }
};
