import axios from "axios";

import { getEnvVars } from "../utils/env-utils";
const { apiConfig } = getEnvVars();

const myAxios = axios.create({
  baseURL: apiConfig.url,
  headers: {
    apikey: apiConfig.apikey,
  },
});

export const getData = (token) => {
  return myAxios.get(apiConfig.medsDataApi, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
