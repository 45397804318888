import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ascensionLogo from "../../assets/ascension-logo-white.svg";
import arches from "../../assets/bg-blue-arch.png";
import routes from "../../constants/routes";

const useStyles = makeStyles({
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    color: "#fff",
    backgroundColor: "#0F3C97",
    padding: "40px",
    boxSizing: 'border-box'
  },
  subFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    color: "#fff",
    backgroundColor: "#1560C5",
    padding: "30px",
    backgroundImage: `url(${arches})`,
    backgroundSize: "cover", 
    "& a": {
      color: "white",
    },
    boxSizing: 'border-box'
  },
  helpText: {
    textAlign: "center",
    fontFamily: "Chronicle Text G1 !important",
    fontWeight: "bold !important",
    fontSize: "32px",
    marginBottom: "10px",
  },
  brand: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    float: "left",
    width: "auto",
    textDecoration: "none",
    "& > h4": {
      margin: 0,
      fontSize: "20px",
    },
  },
  cursor_typography: {
    margin: "5px 0 5px 0",
    cursor: "pointer",
    textDecoration: "none",
    color: "white",
    fontSize: "12px",
    letterSpacing: "0.5px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  typography: {
    margin: "12px 0",
  },
  footerHeaderSmall: {
    display: "flex",
    margin: "20px 0 30px 0",
    justifyContent: "space-around",
  },
  footerHeader: {
    display: "flex",
    margin: "15px 0 20px 50px",
  },
  footerBody: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  bodyFirstName: {
    display: "flex",
    flexDirection: "column",
    margin: "0 0 10px 0",
  },
  bodySecondName: {
    display: "flex",
    flexDirection: "column",
  },
  footerFooter: {
    margin: "5px 0 25px 0",
  },
  footerFooterSmall: {
    margin: "25px 0px",
  },
  footerCaption: {
    padding: "5px",
  },
  footerSocialMedia: {
    display: "flex",
    justifyContent: "space-between",
  },
  footerIcon: {
    display: "flex !important",
  },
  footerSocialButtons: {
    border: "1px solid white",
    borderRadius: "50%",
    height: "35px",
    width: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    color: "white",
    "&:hover": {
      color: "#0F3C97",
      backgroundColor: "white",
    },
  },
  footerLanguageAssitance: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  collapseView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    color: "#fff",
    backgroundColor: "#0F3C97",
  },
  divider: {
    height: "1px",
    margin: "24px 0",
    backgroundColor: "#fafafa",
  },
  collapseHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  collapseBody: {
    marginLeft: "10px",
  },
});

const Footer = () => {
  const classes = useStyles();

  return (
    <footer>
      <div className={classes.subFooter}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            gap: {
              sm: "10px",
            },
            justifyContent: "center",
          }}
        >
          <Typography
            className={classes.helpText}
            sx={{
              fontSize: "32px",
              mb: "10px",
            }}
          >
            Need help?
          </Typography>
          <Typography
            className={classes.helpText}
            sx={{
              fontSize: "32px",
              mb: "10px",
            }}
          >
            <a href="tel:18336337279">Call 1-833-Meds-ARx</a>
          </Typography>
        </Box>
        <Typography align="center" variant="body1">
          Ascension Rx operating hours are Monday through Friday, 8am to 5pm
          CST.
        </Typography>
      </div>
      <div className={classes.footer}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: "40px",
              }}
            >
              <Link to={routes.HOME} className={classes.brand}>
                <img src={ascensionLogo} height="45px" alt="brand" />
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "50px",
              }}
            >
              <div className={classes.bodySecondName}>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: "14px",
                    letterSpacing: "1.75px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    m: 0,
                    mb: "16px",
                  }}
                  className={classes.typography}
                >
                  More at Ascension
                </Typography>

                <a
                  className={classes.cursor_typography}
                  style={{
                    margin: "0 0 16px",
                    fontSize: "14px",
                  }}
                  href=""
                >
                  Contact us
                </a>
                <a
                  className={classes.cursor_typography}
                  style={{
                    margin: "0 0 16px",
                    fontSize: "14px",
                  }}
                  href="https://www.ascension.org"
                >
                  Ascension.org
                </a>
                <a
                  className={classes.cursor_typography}
                  style={{
                    margin: "0 0 16px",
                    fontSize: "14px",
                  }}
                  href=""
                >
                  Home
                </a>
              </div>
              <div className={classes.bodySecondName}>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: "14px",
                    letterSpacing: "1.75px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    m: 0,
                    mb: "16px",
                  }}
                  className={classes.typography}
                >
                  Contact Information
                </Typography>
                <Typography variant="body2">Ascension Rx</Typography>
                <Typography variant="body2">P.O. Box 1707</Typography>
                <Typography variant="body2">Troy, MI 48099</Typography>
                <Typography sx={{ my: "12px" }} variant="body2">
                833-633-7279
                </Typography>
              </div>
            </Box>
          </Box>
          <Divider
            className={classes.divider}
            sx={{ height: "1px", my: "24px" }}
          />
          <div className={classes.footerFooterSmall}>
            <a
              variant="OVERLINE"
              color="inherit"
              className={classes.cursor_typography}
              href="https://healthcare.ascension.org/nondiscrimination-policy"
            >
              Nondiscrimination Policy
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              variant="OVERLINE"
              color="inherit"
              className={classes.cursor_typography}
              href="https://ascension.org/About/Corporate-Responsibility"
            >
              Compliance and Standards of Conduct
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              variant="OVERLINE"
              color="inherit"
              className={classes.cursor_typography}
              href="https://ascension.org/PrivacyPolicy"
            >
              Privacy Policy
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              variant="OVERLINE"
              color="inherit"
              className={classes.cursor_typography}
              href="https://ascension.org/TermsOfUse"
            >
              Term of Use
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              variant="OVERLINE"
              color="inherit"
              className={classes.cursor_typography}
              href="https://healthcare.ascension.org/npp"
            >
              HIPPA Notice of Privacy Practices
            </a>
          </div>
          <div className={classes.footerFooterSmall}>
            <Typography variant="caption" color="inherit">
              Our facilities are currently taking precautions to help keep
              patients and visitors safe, which may include conducting
              screenings, restricting visitors and practicing distancing for
              compassionate, safe care. We continuously monitor COVID-19
              guidance from the Centers for Disease Control and Prevention (CDC)
              and adjust our safety practices and safeguards accordingly.
            </Typography>
          </div>
          <Typography variant="body2" color="inherit" sx={{ fontSize: 12 }}>
            Copyright {(new Date().getFullYear())} Ascension. All rights reserved.
          </Typography>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
