import { createTheme } from "@mui/material/styles";
import { theme } from "@ascension/ui-library";

export const ascensionUITheme = createTheme({
  palette: {
    primary: {
      main: "#1E69D2",
      dark: "#0F3C97",
    },
    secondary: {
      main: "#B40F87",
      dark: "#810778",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: "#00A791",
    },
    warning: {
      main: "#DB6200",
    },
    divider: "rgba(0, 0, 0, 0.15)",
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: "48px",
      fontWeight: 400,
      padding: "1em 0 1rem",
      letterSpacing: "0.5px",
      lineHeight: "55px",
      fontFamily: '"Chronicle Text G1", "Frank Ruhl Libre", serif !important',
      position: "relative",
      marginBottom: "1em",
      "&:after": {
        content: '""',
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 40,
        borderBottom: "6px solid #B40F87",
      },
    },
    h2: {
      fontSize: "1.8rem",
      fontFamily: '"Frank Ruhl Libre", serif !important',
      fontWeight: 500,
      padding: "1em 0 0.5em",
      marginBottom: "1em",
      letterSpacing: "-0.03em",
      position: "relative",
      "&:after": {
        content: '""',
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 40,
        borderBottom: "6px solid #B40F87",
      },
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
      padding: "1em 0 0.5em",
      letterSpacing: 0,
    },
    h4: {
      fontSize: "1.3rem",
      fontWeight: 600,
      padding: "1em 0 0.5em",
      letterSpacing: 0,
      color: "#B40F87",
    },
    h5: {
      fontSize: "1.1rem",
      fontWeight: 500,
      letterSpacing: 0,
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      margin: "16px 0",
    },
    fontFamily: theme.typography.fontFamily,
  },
});

ascensionUITheme.overrides = {
  MuiPaper: {
    root: {
      // backgroundColor: 'transparent',
      // boxShadow: '0 0 0 0',
      padding: ascensionUITheme.spacing(1.5, 1),
    },
    elevation1: {
      //   backgroundColor: "transparent",
      // boxShadow: '0 0 0 0',
    },
  },
  MuiButton: {
    root: {
      fontWeight: 500,
      color: "white",
      letterSpacing: 0,
      padding: ascensionUITheme.spacing(0.5, 2),
      backgroundColor: ascensionUITheme.palette.primary.main,
    },
    text: {
      padding: ascensionUITheme.spacing(0.5, 2),
    },
    textSizeSmall: {
      padding: ascensionUITheme.spacing(0.5, 2),
    },
    outlined: {
      backgroundColor: "transparent",
      color: ascensionUITheme.palette.primary.main,
      borderColor: ascensionUITheme.palette.primary.main,
    },
  },
  MuiInputBase: {
    input: {
      backgroundColor: "white",
      padding: ascensionUITheme.spacing(1.5, 1),
    },
    multiline: {
      backgroundColor: "white",
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: ascensionUITheme.spacing(1.5, 1),
    },
    multiline: {
      padding: ascensionUITheme.spacing(1.5, 1),
    },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: ascensionUITheme.spacing(1),
    },
  },
  MuiCard: {
    root: {
      backgroundColor: "white",
    },
  },
  MuiCardHeader: {
    title: {
      fontSize: "1.4rem",
      lineHeight: 1.1,
    },
  },
  MuiDivider: {
    middle: {
      margin: ascensionUITheme.spacing(2, 0),
    },
  },
};

export default ascensionUITheme;
