import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./config";

const RequireAuth = ({ children }) => {
  const { inProgress, instance } = useMsal();
  const account = instance.getActiveAccount();

  const isAuthenticated = useIsAuthenticated();
  // const isAuthenticated = false;

  const pendingResponse =
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect;

  if (isAuthenticated) {
    return <>{children}</>;
  } else if (pendingResponse) {
    return null;
  } else {
    //Attempt Login
    const request = { ...loginRequest, account };

    instance
      .acquireTokenSilent(request)
      .then((res) => {
        console.log(res.accessToken);
      })
      .catch(() => {
        instance.acquireTokenRedirect(request).then((res) => {
          console.log(res.accessToken);
        });
      });

    return null;
  }
};

export default RequireAuth;
