import React from "react";
import { Card, CardContent, Box, Typography } from "@mui/material";
import question from "../../assets/ascension-question.svg";
import { Button } from "@ascension/ui-library";

const FaqCard = () => {
  return (
    <Card sx={{ my: "48px" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: { xs: "center", sm: "start" },
        }}
      >
        <Box
          sx={{
            height: "155px",
            width: "155px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={question}
            alt="Violet question mark outline"
            height="118px"
          />
        </Box>
        <Box sx={{ marginLeft: "24px" }}>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontSize: "24px",
              lineHeight: "24px",
              fontWeight: "normal",
              p: 0,
            }}
          >
            FAQ
          </Typography>
          <Typography variant="body1" sx={{ my: "16px" }}>
            Read our FAQ for answers on topics such as receiving medications,
            cost, and managing refills.
          </Typography>
          <Button
            variant="outlined"
            size="large"
            href="https://healthcare.ascension.org/ascensionrxtransfer/arx-faq"
            target="_blank"
          >
            View the Full FAQ
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FaqCard;
