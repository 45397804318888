import { PublicClientApplication } from "@azure/msal-browser";
import routes from "../constants/routes";
import { getEnvVars } from "../utils/env-utils";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

const {
  authConfig: { applicationId, authority, scopes },
} = getEnvVars();

const policies = {
  login: "B2C_1A_SIGNUP_SIGNIN_MEMBER",
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: applicationId,
    authority: `${authority}/${policies.login}`,
    redirectUri: routes.HOME,
    postLogoutRedirectUri: routes.HOME,
    knownAuthorities: [authority],
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes,
};

export const withAccessToken = (cb) => {
  const account = msalInstance.getActiveAccount();
  return msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account,
    })
    .then((res) => {
      cb(res.accessToken);
    })
    .catch(() => {
      msalInstance
        .acquireTokenSilent({
          ...loginRequest,
          account,
        })
        .then((res) => {
          cb(res.accessToken);
        });
    });
};
