import React, { useState, useEffect } from "react";
import { Paper, Typography, Alert, Container, Box, Grid, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MaterialTable from "@material-table/core";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const MedsTable = ({ loading = false, data = [] }) => {
  const [filterData, setfilterData] = useState();  
  const [checked, setChecked] = useState(false);

  useEffect(()=>{
    if(checked){
      setfilterData(data);
    }
    else {
      var fData = data.filter(fData => fData.rxStatus === "Actionable");
      setfilterData(fData);
    }
  }, [checked]);

  const handleIncludeDiscontinued = (event) => {
    setChecked(event.target.checked);
  };


  return (
    <>
      <Container maxWidth={"lg"}>
        <Grid container spacing={2} marginTop={5}>
          <Grid item xs={8} md={8}>
            <Typography variant="h3" component="h2">
              My Medications
            </Typography>
          </Grid>

          <Grid item xs={4} md={4} >
            <Typography variant="h3" component="h2">
              <label>Include Discontinued:</label>
              <FormControlLabel
                sx={{ '& .MuiSvgIcon-root': { fontSize: 32, marginLeft: 2 } }}
                control={<Checkbox
                  checked={checked}
                  onChange={handleIncludeDiscontinued}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>
      
      {filterData && filterData.length > 0 ? ( // display table if prescriptions are found
        <>
          <Container maxWidth={"xl"}>
            <MaterialTable
              isLoading={loading}
              options={{
                search: false,
                paging: false,
                padding: "dense",
                headerStyle: {
                  fontWeight: "bold",
                  color: "rgba(0,0,0,0.6)",
                  padding: "0",
                  borderBottomColor: "rgba(0,0,0,0.5)",
                },
                cellStyle: {
                  padding: "24px 0 8px",
                },
                toolbar: false,
              }}
              localization={{
                body: {
                  emptyDataSourceMessage:
                    "Your prescription status will appear here when they are recorded in our system.",
                },
              }}
              components={{
                Container: (props) => (
                  <Paper
                    sx={{
                      p: "24px",
                      my: 4,
                      "& tbody tr:last-child td": {
                        border: 0,
                      },
                    }}
                    {...props}
                  />
                ),
                Toolbar: (props) => (
                  // <MTableToolbar {...props} style={{ padding: "0", fontSize: "24px" }} />
                  <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                      fontSize: "24px",
                      lineHeight: "24px",
                      fontWeight: "normal",
                      py: "24px",
                    }}
                  >
                    {props.title}                 
                  </Typography>
                ),
              }}
              columns={[
                {
                  title: "Facility ID",
                  field: "facilityId",
                  width: 100,
                  tooltip: "Your Ascension Rx location or store number where the prescription exists *Note - this is also the first four digits of your Prescription# after the dash on your prescription bottle in the upper left corner.",
                },
                {
                  title: "Rx #",
                  field: "rxNumber",
                  width: 100,
                  tooltip: "Your unique prescription identifier on the upper left corner of your label, before the dash, but not including the numbers/letters after the dash.",
                },
                { title: "Medication Name", 
                  field: "medicationName",
                  width: 400,
                  tooltip: "The Medication Name - identified on your label just underneath your name.",
                },
                { title: "Rx Status",
                  field: "rxStatus",
                  tooltip: "Whether your prescription is still able to be acted on in the system (Actionable), or is no longer current, so not actionable (Discontinued).",
                },
                {
                  title: "Last Filled Date",
                  field: "lastFilledDate",
                  type: "date",
                  tooltip: "The last date when the prescription was filled.",
                },
                {
                  title: "Fill Status",
                  field: "fillStatus",
                  tooltip: "Ready, Shipped / Picked Up, Insurance Issue*, Provider Action Needed, In Process, Missing Payment Info.",
                },
                {
                  title: "Refills Remaining",
                  field: "refillsRemaining",
                  type: "numeric",
                  align: "center",
                  tooltip: "The number of refills that remain on your prescription. *Note that partial fills, or the way the prescriber wrote the prescription may lead to a misleading or incorrect calculation for refills stated. Your prescription bottle label or pharmacy is the best source for the most accurate number of refills remaining.",
                },
              ]}
              data={filterData}
            />
            {/* {data.some((row) => row.fillStatus.length>0) && ( */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Typography variant="body2">
                  * Contact ARx Service Center at 1-833-Meds-ARx for more
                  information
                </Typography>
              </Box>
            {/* )} */}
          </Container>
        </>
      ) : (
        // display info alert if no prescriptions are found
        <Container maxWidth={"lg"}>
          <Alert
            elevation={0}
            severity="info"
            iconMapping={{
              info: <InfoIcon color="primary" />,
            }}
            sx={{
              my: 4,
              bgcolor: "white",
              borderWidth: "1px",
              borderLeftWidth: "4px",
              borderStyle: "solid",
              borderColor: "primary.main",
              color: "text.primary",
            }}
          >
            <Typography variant="body1" sx={{ my: 0 }}>
              Your medications will appear here when they've been recorded in our
              system.
            </Typography>
          </Alert>
        </Container>
      )}
    </>
  );
};

export default MedsTable;
