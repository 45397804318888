import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Link,
} from "@mui/material";
import { useAccount } from "@azure/msal-react";
import { getData } from "../../api/apiCalls";
import MedsTable from "./MedsTable";
import AccountAlert from "./AccountAlert";
import FaqCard from "./FaqCard";
import MobileAppInfo from "./MobileAppInfo";
import { withAccessToken } from "../../auth/config";

const MyMedsController = () => {
  const [medsData, setMedsData] = useState(null);
  const [accountError, setAccountError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let account = useAccount() || {
    idTokenClaims: { firstName: "", lastName: "" },
  }; // null check for testing

  const resetState = () => {
    setMedsData(null);
    setAccountError(false);
    setErrorMessage(null);
    setIsLoading(false);
  };

  useEffect(() => {
    resetState();
    setIsLoading(true);
    withAccessToken((token) => {
      getData(token)
        .then((res) => {
          setMedsData(res.data);
        })
        .catch(({ response: data }) => {
          if (
            data.status === 400 &&
            data.data.message === "Patient Id not found"
          ) {
            setAccountError(true);
          } else {
            console.error({ data });
            setErrorMessage(data.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "#e5e5e5",
        pb: 10,
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h1">
          Hello, {account.idTokenClaims.firstName}
        </Typography>
      </Container>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ my: "24px" }} />
        </Box>
      ) : (
        // Error state
        <>
          {!!errorMessage && (
            <AccountAlert severity="error">
              <Typography variant="body1" sx={{ my: 0 }}>
                There was an problem while retrieving your data. Please try
                again later or contact the customer center at 833-Meds-ARx for
                more help.
              </Typography>
            </AccountAlert>
          )}
          {accountError ? (
            <>
              <AccountAlert severity="warning">
                <Typography variant="body1" sx={{ mt: 0 }}>
                  <b>
                    We were unable to find an Ascension Rx account that matches
                    your information.
                  </b>{" "}
                  It may take some time for our pharmacy team to complete your
                  account setup.
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body1">
                      If you have not started the transfer process, please
                      complete this first:{" "}
                      <Link
                        underline="hover"
                        href="https://healthcare.ascension.org/ascensionrxtransfer/form"
                      >
                        Start Medication Transfer
                      </Link>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      If it has been more than 72 hours since you submitted the
                      transfer form, you may need to complete the{" "}
                      <Link
                        underline="hover"
                        href="https://healthcare.ascension.org/ascensionrxtransfer/form"
                      >
                        medication transfer form
                      </Link>{" "}
                      again. We are sorry for this inconvenience.
                    </Typography>
                  </li>
                </ul>
              </AccountAlert>
              <AccountAlert severity="info">
                <Typography variant="body1" sx={{ mt: 0 }}>
                  If you haven’t already done so, tell your doctor or prescriber
                  to send your prescriptions to:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body1">
                      "Ascension Rx 1001 Home Delivery" for maintenance
                      medications
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      "Ascension RX 1303 - Speciality" for speciality
                      medications
                    </Typography>
                  </li>
                </ul>
                <Typography variant="body1">
                  Prescriptions can also be faxed to 1-833-347-0798.
                </Typography>
              </AccountAlert>
            </>
          ) : (
            // End error state
            <>
              {medsData && medsData.length === 0 && (
                // Show alert if account found but no prescriptions
                <>
                  <AccountAlert severity="info">
                    <Typography variant="body1" sx={{ mt: 0 }}>
                      <b>We did not find any prescriptions for your account.</b>{" "}
                      We generally process prescriptions as soon as they are
                      sent to us but sometimes this can take longer than normal.
                      Check back later to see if your prescriptions have been
                      processed.
                    </Typography>
                    <Divider
                      sx={{
                        my: 2,
                      }}
                    />
                    <Typography variant="body1">
                      If you haven’t already done so, tell your doctor or
                      prescriber to send your prescriptions to:
                    </Typography>
                    <ul>
                      <li>
                        <Typography variant="body1">
                          "Ascension Rx 1001 Home Delivery" for maintenance
                          medications
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          "Ascension RX 1303 - Speciality" for speciality
                          medications
                        </Typography>
                      </li>
                    </ul>
                    <Typography variant="body1">
                      Prescriptions can also be faxed to 1-833-347-0798.
                    </Typography>
                  </AccountAlert>
                </>
              )}
              {medsData && <MedsTable loading={isLoading} data={medsData} />}
            </>
          )}
        </>
      )}
      <Container maxWidth={"lg"}>
        <Typography variant="body1">
          Ascension Rx Website: 
            <a href="https://ascension.medrefill.com/asweb/#/home" target="_blank">Ascension Rx</a>
        </Typography>
        <Typography variant="body1">
          Add/Edit Credit Card Info:   
            <a href="https://rx-onboarding-ui.pub.cloud-03.pcf.ascension.org/" target="_blank">Ascension Rx Payment Details</a>
        </Typography>
      </Container>

      <Container maxWidth={"lg"}>
        <MobileAppInfo />

        <FaqCard />
      </Container>
    </Box>
  );
};

export default MyMedsController;
