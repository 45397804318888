import React from "react";
import { Alert, Container } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/WarningOutlined";
import ErrorIcon from "@mui/icons-material/Error";

const AccountAlert = ({ severity, children }) => (
  <Container maxWidth={"lg"}>
    <Alert
      elevation={1}
      severity={severity}
      iconMapping={{
        info: <InfoIcon color="primary" />,
        warning: <WarningIcon color="warning" />,
        error: <ErrorIcon color="error" />,
      }}
      sx={{
        my: 4,
        bgcolor: "white",
        borderLeftWidth: "4px",
        borderLeftStyle: "solid",
        borderLeftColor: `${severity}.main`,
        color: "text.primary",
      }}
    >
      {children}
    </Alert>
  </Container>
);

export default AccountAlert;
