import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MyMedsController from "../MyMeds/MyMedsController";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ascensionUITheme from "../common/ascensionUITheme";
import RequireAuth from "../../auth/RequireAuth";


const Redirect = () => {
  window.location.replace("https://rx-onboarding-ui.pub.cloud-03.pcf.ascension.org/");
  return(
    <p>Redirecting to Rx Onboarding...</p>
  )
}
function App() {
  return (
    <ThemeProvider theme={ascensionUITheme}>
      <BrowserRouter>
        <CssBaseline />
        <Routes>
          <Route exact path="/" element={
            <RequireAuth>
              <Header />
              <MyMedsController />
              <Footer />
            </RequireAuth>
          } />
          <Route exact path="/addcard" element={<Redirect/>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
