import React from "react";
import { Typography, Box, Card, CardContent } from "@mui/material";
import app_store from "../../assets/download-on-the-app-store.svg";
import google_play from "../../assets/googleplaybadge.png";
import rx_bottle from "../../assets/rx_bottle.png";

const MobileAppInfo = () => {
  return (
    <Card sx={{ my: "48px" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: { xs: "center", sm: "start" },
        }}
      >
        <Box
          sx={{
            height: "155px",
            width: "155px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={rx_bottle}
            alt="Violet question mark outline"
            height="106px"
          />
        </Box>
        <Box sx={{ marginLeft: "24px" }}>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontSize: "24px",
              lineHeight: "24px",
              fontWeight: "normal",
              p: 0,
            }}
          >
            Download the App
          </Typography>
          <Typography variant="body1">
            Set up the Ascension Rx app with your prescription number* and
            enjoy the following features:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                Have an up-to-date list of your prescriptions in your pocket.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Never miss a dose. Get reminders when its time to take your
                meds.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Refill by simply scanning your prescriptions.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Get a message when your prescription is ready.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1">
            Download the Ascension Rx app on your smartphone:
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              gap: "24px",
              my: "24px",
            }}
          >
            <a href="https://apps.apple.com/us/app/ascension-rx/id1587111034">
              <img
                src={app_store}
                alt="Download on the App Store"
                height="46px"
              />
            </a>
            <img
              src={google_play}
              alt="Get it on Google Play"
              height="46px"
              width="153px"
            />
          </Box>
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              lineHeight: "18px",
              color: "rgba(0,0,0,0.87)",
            }}
          >
            *You will need a prescription number for an Ascension Rx dispensed
            medication to set up your account.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MobileAppInfo;
